const translations = {
    eng: {
        title: "Why did Monivate charge me?",
        description: "Don’t worry, we are here to assist you with billing questions. Please provide us with the required information and our Support Team will reply to you as soon as possible.",
        enterNameAndEmail: "Please enter your name and email",
        enterDateAndAmount: "Specify the date and amount of the transaction",
        required: "required",
        amount: "Amount",
        date: "Date",
        firstSix: "Indicate the first six and the last four digits of the card",
        card: "Card Number",
        issueDetails: "Issue details",
        otherInfo: "Describe your issue in detail",
        sendRequest: "Send",
        moneyBack: "100% Money-Back Guarantee Policy",
        canRequestRefund: "We will provide a full refund if you don’t like our service.",
        safetyGuarantee: "Safety Guarantee",
        personalInfo: "Your personal information is entirely safe",
        securityGuarantee: "Security Guarantee",
        allTransactions: "Payments are totally secure.",
        errorEmail: "Incorrect email",
        errorDate: "Date incorrect",
        errorAmount: "Enter the amount",
        errorCard: "Enter the first six and last four digits of the card",
        name: "Name",
        email: "Email",
        appAccepted: "Your application has been accepted!",
        weWillReply: "We will reply to your message via email within 24 hours. Please do not forget to check your spam folder",
        ok: "Okay",
        tryAgain: "Please check internet connection and try again.",
        attachFile: "Attach the screenshot of the payment",
        dragAndDrop: "Drag and drop, or browse to attach.",
    },
};

export default translations;
