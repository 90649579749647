import React, { useState } from 'react';
import Header from './components/Header'
import Description from "./components/Description";
import Form from "./components/Form";
import Advantages from "./components/Advantages";
import Modal from "./components/Modal";
import translations from './translations'
//import {initAnalytics, AmplitudeWrapper} from './Analytics'
import Footer from "./components/Footer";
import './App.scss';

const LEGAL_DATA = {
    'common': {
        entity: 'Recibar',
        name: 'Recibar Inc.',
        address: '4031 Colonel Glenn Hwy, Suite 450 \n' +
            'Beavercreek Township, Ohio 45431',
        email: 'support@monivate.io',
        phoneNumber: '+1 (256) 448-1111',
        support: '+1 (844) 702-0521'
    },
    'billmonivate.help': {
        entity: 'ConabiousLTD',
        name: 'Conabious LTD',
        address: 'Aglantzias, 21B, 2nd floor, office 1 \n' +
            'Aglantzia, 2108, Nicosia, Cyprus',
        email: 'support@monivate.io',
        phoneNumber: '+1 (256) 448-1111',
        support: '+1 (844) 702-0521'
    },
    'monivatebill.help': {
        entity: 'FT_SICH_FZCO',
        name: 'FT SICH FZCO',
        address: 'IFZA Business Park, DDP, Building A2 \n' +
            '21379-001, Dubai, United Arab Emirates',
        email: 'support@monivate.io',
        phoneNumber: '+1 (256) 448-1111',
        support: '+1 (844) 702-0521'
    },
};

//initAnalytics();

function App() {
    const host = window.location.host;
    const legalDataByHost = LEGAL_DATA[host] || LEGAL_DATA['common'];
    //const legalName = legalDataByHost.entity;

    /*useEffect(()=> {
        AmplitudeWrapper('billTicketOpen', {
            result: legalName,
        });
    }, []);*/

    const [isShowModal, toggleModal] = useState(false);
    const [language, setLanguage] = useState('eng');

    const handleChangeLanguage = (lang) => {
        setLanguage(lang);
    };

    return (
        <div className="app">
            <div className="app_container">
            <Header
                language={language}
                cb={handleChangeLanguage}
            />
            <Description
                translations={translations[language]}
            />
            <Form
                toggleModal={toggleModal}
                translations={translations[language]}
            />
            <Advantages
                translations={translations[language]}
            />
            <Modal
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                translations={translations[language]}
            />
            <Footer
                data={legalDataByHost}
            />
            </div>
        </div>
    );
}

export default App;
